import EmblaCarousel, { EmblaOptionsType } from 'embla-carousel';
import Autoplay from 'embla-carousel-autoplay';
import Fade from 'embla-carousel-fade';

const OPTIONS: EmblaOptionsType = { loop: true };

export const carousel = () => {
  const carousels = document.querySelectorAll('.wp-block-gallery.is-style-hero');

  if (!carousels.length) return;

  carousels.forEach((emblaNode) => {
    emblaNode.classList.add('embla');

    // create wrapper div
    const viewportNode = document.createElement('div');
    viewportNode.classList.add('embla__viewport');

    const containerNode = document.createElement('div');
    containerNode.classList.add('embla__container');

    // add all children to wrapper div
    emblaNode.querySelectorAll('.wp-block-image').forEach((child) => {
      child.classList.add('embla__slide');
      containerNode.appendChild(child);
    });

    viewportNode.appendChild(containerNode);

    emblaNode.appendChild(viewportNode);

    EmblaCarousel(viewportNode, OPTIONS, [
      Autoplay({
        stopOnInteraction: false,
        delay: 4000,
      }),
      Fade(),
    ]);
  });
};
