export const desktopMenu = () => {
  // calc header height and set css var --header-height
  const header = document.querySelector('.site-header');

  if (!header) return;

  const menuItemsWithChildren = header.querySelectorAll('.menu-item-has-children');
  const menuLinks = header.querySelectorAll('a');

  const isDesktop = window.matchMedia('(min-width: 1024px)');

  menuItemsWithChildren.forEach((item) => {
    const subMenu = item.querySelector('.sub-menu');

    if (!subMenu) return;

    item.addEventListener('mouseenter', () => {
      if (!isDesktop.matches) return;

      item.classList.add('is-active');
    });
  });

  menuLinks.forEach((link) => {
    link.addEventListener('mouseenter', () => {
      if (!isDesktop.matches) return;

      menuItemsWithChildren.forEach((item) => {
        if (!item.contains(link)) {
          item.classList.remove('is-active');
        }
      });
    });
  });

  header.addEventListener('mouseleave', () => {
    if (!isDesktop.matches) return;

    menuItemsWithChildren.forEach((item) => {
      item.classList.remove('is-active');
    });
  });
};
